import React, { useState, useEffect, useCallback } from 'react';

const TextPreview = ({ file, url }) => {
    const [content, setContent] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    // Simple function to escape HTML in text content - wrapped in useCallback
    const escapeHtml = useCallback((text) => {
        const div = document.createElement('div');
        div.textContent = text; // Browser handles the escaping
        return div.textContent || div.innerText || '';
    }, []);

    useEffect(() => {
        const loadFile = async () => {
            try {
                setIsLoading(true);
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`Failed to load file: ${response.status} ${response.statusText}`);
                }
                const text = await response.text();
                setContent(text);
                setIsLoading(false);
            } catch (err) {
                console.error('Error loading text file:', err);
                setError('Error loading file');
                setIsLoading(false);
            }
        };

        loadFile();
    }, [url, file]); // Added file dependency

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-full">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-full">
                <div className="text-red-500">{error}</div>
            </div>
        );
    }

    // No need for dangerouslySetInnerHTML for text files
    // Just render as plain text with proper escaping
    return (
        <div className="h-full overflow-auto p-4 bg-white">
            <pre className="whitespace-pre-wrap font-mono text-sm text-gray-800">
                {content}
            </pre>
        </div>
    );
};

export default TextPreview; 