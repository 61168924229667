import React, { useState, useEffect } from 'react';
import mammoth from 'mammoth';
import DOMPurify from 'dompurify';

const DocxPreview = ({ file, url }) => {
    const [content, setContent] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loadFile = async () => {
            try {
                setIsLoading(true);
                console.log('Loading DOCX file:', file.original_name);

                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`Failed to load file: ${response.status} ${response.statusText}`);
                }

                const arrayBuffer = await response.arrayBuffer();

                // Enhanced options for mammoth.js
                const options = {
                    arrayBuffer: arrayBuffer,
                    convertImage: mammoth.images.imgElement(function (image) {
                        return {
                            src: image.src
                        };
                    }),
                    ignoreEmptyParagraphs: false,
                    styleMap: [
                        "p[style-name='Section Title'] => h1:fresh",
                        "p[style-name='Subsection Title'] => h2:fresh",
                        "p[style-name='Heading 1'] => h1:fresh",
                        "p[style-name='Heading 2'] => h2:fresh",
                        "p[style-name='Heading 3'] => h3:fresh",
                        "p[style-name='Heading 4'] => h4:fresh",
                        "p[style-name='Heading 5'] => h5:fresh",
                        "p[style-name='Heading 6'] => h6:fresh",
                        "r[style-name='Strong'] => strong",
                        "r[style-name='Emphasis'] => em"
                    ]
                };

                // Use mammoth to convert the docx to html
                console.log('Starting DOCX conversion');
                const result = await mammoth.convertToHtml(options);
                console.log('DOCX conversion completed');

                // Sanitize the HTML content using DOMPurify
                const sanitizedHtml = DOMPurify.sanitize(result.value, {
                    USE_PROFILES: { html: true },
                    ALLOWED_TAGS: [
                        'p', 'div', 'span',
                        'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
                        'ul', 'ol', 'li',
                        'table', 'tr', 'td', 'th', 'thead', 'tbody',
                        'br', 'strong', 'em', 'b', 'i', 'u', 'img'
                    ],
                    ALLOWED_ATTR: ['href', 'src', 'alt', 'title', 'class']
                });

                const styledHtml = `
                    <div class="word-document">
                        ${sanitizedHtml}
                    </div>
                `;

                setContent(styledHtml);

                // Log any warnings
                if (result.messages.length > 0) {
                    console.log('Mammoth warnings:', result.messages);
                }

                setIsLoading(false);
            } catch (err) {
                console.error('Error loading Word document:', err);
                setError(`Error loading file: ${err.message}`);
                setIsLoading(false);
            }
        };

        loadFile();
    }, [file, url]);

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-full">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-full">
                <div className="text-red-500">{error}</div>
            </div>
        );
    }

    return (
        <div className="h-full overflow-auto p-4 bg-white">
            <div
                className="document-content max-w-none"
                dangerouslySetInnerHTML={{ __html: content }}
                style={{
                    lineHeight: '1.2',
                    fontSize: '16px'
                }}
            />
            <style jsx>{`
                .word-document {
                    font-family: 'Calibri', 'Arial', sans-serif;
                }
                .word-document p {
                    margin-top: 0.5rem;
                    margin-bottom: 0.5rem;
                }
                .word-document h1, .word-document h2, .word-document h3,
                .word-document h4, .word-document h5, .word-document h6 {
                    margin-top: 1rem;
                    margin-bottom: 0.5rem;
                    line-height: 1.2;
                }
                .word-document table {
                    border-collapse: collapse;
                    margin: 1rem 0;
                    width: 100%;
                }
                .word-document table td,
                .word-document table th {
                    border: 1px solid #ddd;
                    padding: 6px 8px;
                    line-height: 1.2;
                }
                .word-document ul, 
                .word-document ol {
                    padding-left: 2rem;
                    margin-top: 0.5rem;
                    margin-bottom: 0.5rem;
                }
                .word-document li {
                    margin-top: 0.25rem;
                    margin-bottom: 0.25rem;
                }
                .word-document img {
                    max-width: 100%;
                    height: auto;
                }
            `}</style>
        </div>
    );
};

export default DocxPreview; 